export type DetailCardProps = {
  icon: JSX.Element;
  title: string;
  paragraph: string;
};

function DetailCard({icon, title, paragraph}: DetailCardProps) {
  return (
    <div className="flex flex-col gap-4 p-8 h-100 w-100 bg-white shadow-lg text-center items-center">
      {icon}
      <h3 className="text-2xl font-extrabold">{title}</h3>
      <p>{paragraph}</p>
    </div>
  );
}

export default DetailCard;
