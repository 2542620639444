import Image from "next/image";
import StarRating from "@/components/StarRating";
import {filter} from "lodash";
import dayjs from "dayjs";

export function Testimonials({reviews}) {
  const filtered = filter(reviews, (r) => !!r.comment)
    .slice(0, 6)
    .map((r) => {
      let rating = 5;
      switch (r.starRating) {
        case "FIVE":
          rating = 5;
          break;
        case "FOUR":
          rating = 4;
          break;
        case "THREE":
          rating = 3;
          break;
        case "TWO":
          rating = 2;
          break;
        case "ONE":
          rating = 1;
          break;
      }
      return {...r, rating};
    });

  return (
    <section id="testimonials" aria-labelledby="testimonials-title">
      <div className="mx-auto max-w-2xl md:text-center">
        <h2 id="testimonials-title" className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
          Don't take our word for it.
        </h2>
        <p className="mt-4 text-lg tracking-tight text-slate-700">Here's what our clients have to say.</p>
      </div>
      <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none sm:grid-cols-2 lg:grid-cols-3">
        {filtered.map((testimonial, testimonialIndex) => (
          <li key={testimonialIndex}>
            <ul className="space-y-6 sm:space-y-8">
              <li>
                <figure className="relative bg-white p-6 shadow-lg shadow-slate-900/10">
                  <svg aria-hidden="true" width={105} height={78} className="absolute top-6 left-6 fill-slate-100">
                    <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
                  </svg>
                  <blockquote className="relative">
                    <p className="text-lg tracking-tight text-slate-900 line-clamp-4">{testimonial.comment}</p>
                    <p className="text-sm text-gray-300 mt-1">{dayjs(testimonial.createTime).fromNow()}</p>
                  </blockquote>
                  <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                    <div>
                      <div className="font-display text-base text-slate-900">{testimonial.reviewer.displayName}</div>
                      <div className="mt-1 text-sm text-slate-500">
                        <StarRating rating={testimonial.rating} readOnly={true} />
                      </div>
                    </div>
                    <div className="h-14 w-14 overflow-hidden bg-slate-50">
                      <Image src={testimonial.reviewer.profilePhotoUrl} alt="" height={56} width={56} />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </section>
  );
}
