import React from "react";
import styles from "@/styles/star-rating.module.scss";

export default function StarRating({readOnly = false, rating, format = "long"}) {
  const formattedStars = [
    {index: 0, active: false},
    {index: 1, active: false},
    {index: 2, active: false},
    {index: 3, active: false},
    {index: 4, active: false}
  ].map((s) => ({...s, active: s.index < rating}));

  if (format === "long") {
    return (
      <div className={`flex ${styles.stars}`}>
        {formattedStars.map((s, i) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            key={i}
            className={`${styles.star} ${s.active && styles.active} ${!readOnly && styles.readOnly}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
            />
          </svg>
        ))}
      </div>
    );
  } else {
    return (
      <div className={`flex ${styles.stars}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.star} ${styles.active} ${!readOnly && styles.readOnly}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
          />
        </svg>
        <span className="ml-1 text-xs font-medium text-gray-500">{rating.toFixed(1)}</span>
      </div>
    );
  }
}
