import Header from "@/components/header";
import Head from "next/head";
import Home from "@/components/home";
import Footer from "@/components/footer";
import React from "react";

function Index({reviews}) {
  return (
    <>
      <Head>
        <title>Arke Fitness</title>
      </Head>

      <main>
        <Header />
        <Home reviews={reviews} />
        <Footer />
      </main>
    </>
  );
}

export async function getStaticProps() {
  const res = await fetch(`https://arkefitness.com/api/reviews`);
  const {reviews} = await res.json();

  return {
    props: {
      reviews
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once once a day
    revalidate: 86400
  };
}

export default Index;
