import React, {useEffect, useState} from "react";
import AOS from "aos";

import "aos/dist/aos.css";
import Image from "next/image";
import {CheckIcon} from "@heroicons/react/outline";
import MetaTags from "@/components/metatags";
import {Autoplay, EffectFade, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {Testimonials} from "@/components/Testimonials";
import DetailCard from "./DetailCard";

const why = [
  {
    title: "Gain control over your body",
    description:
      "We will help you manage whatever life throws at you and promote the control you deserve over your own body. We guide you towards a complete understanding of how your body works and instill the confidence required to live your life to the fullest.",
    imageUrl: "benefit-1"
  },
  {
    title: "Achieve sustainable results",
    description:
      "We move away from fleeting trends and short-term desires. Health & fitness are lifelong pursuits, and your results will be attainable and sustainable in the long term. Ultimately, what we ask from our clients is to trust and enjoy the process.",
    imageUrl: "benefit-2"
  },
  {
    title: "Overcome injuries",
    description:
      "Never move in fear again of any past injuries or trauma. We put science to practice by teaching you better quality movements to elevate your living standards. Longevity is what we seek to achieve for our clients.",
    imageUrl: "benefit-3"
  }
];

const benefits = [
  {
    name: "Regain control",
    description: "Benefit Description",
    icon: CheckIcon
  },
  {
    name: "Understand your body",
    description: "Benefit Description",
    icon: CheckIcon
  },
  {
    name: "Complete confidence",
    description: "Benefit Description",
    icon: CheckIcon
  },
  {
    name: "Improve standards of living",
    description: "Benefit Description",
    icon: CheckIcon
  },
  {
    name: "Prevent injuries",
    description: "Benefit Description",
    icon: CheckIcon
  },
  {
    name: "Sustainable results",
    description: "Benefit Description",
    icon: CheckIcon
  }
];

const slides = ["hero-1", "hero-2", "hero-3", "hero-4", "hero-5", "hero-6"];

export default function Home({reviews = []}) {
  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
      once: true,
      offset: 50
    });
  }, []);

  const [isEnabled, setIsEnabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  return (
    <>
      <MetaTags title="Arke Fitness" description="We bridge the gap between Physiotherapy and Personal Training." />
      <main className="bg-almostwhite-500">
        <Swiper
          pagination={true}
          modules={[Pagination, EffectFade, Autoplay]}
          className="hero-swiper"
          effect={"fade"}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
        >
          {slides.map((s, i) => (
            <SwiperSlide key={i}>
              <div className="relative h-full w-full">
                <Image
                  layout="fill"
                  priority={i === 0}
                  className="w-full h-full object-cover brightness-75"
                  src={`/images/${s}.jpg`}
                  alt={`Arke Fitness Image ${i}`}
                />
              </div>
              <div className="absolute bottom-14 md:bottom-32 left-4 right-4 md:left-16 md:right-16 max-w">
                <h1 className="font-arke text-3xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Achieve your fitness goals with the Arke System</span>
                </h1>
                <p className="mt-3 text-base text-almostwhite-500 sm:text-lg md:mt-5 md:text-2xl md:max-w-4xl">
                  Combining Physiotherapy & Personal Training best practices into one.
                </p>
                <div className="mt-5 md:mt-8">
                  <div className="mt-3 sm:mt-0">
                    <a href="https://wa.me/message/AESXYUNFR6NNK1" target="_blank" rel="noreferrer">
                      <a className="af-button-primary md:af-button-big shadow">Reach out to us today</a>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* OUR VALUES */}
        <div className="relative bg-almostwhite-500 my-12">
          <div className="grid max-w-7xl mx-auto p-6 gap-16">
            <div className="flex flex-col gap-4 text-center">
              <h3 className="text-xl lg:text-2xl font-extrabold">
                At Arke Fitness, we've developed a better way to take care of your health.
              </h3>
              <h2 className="text-3xl lg:text-4xl font-extrabold">This is the modern approach to physical care:</h2>
            </div>

            <div className="grid gap-16 w-full md:grid-cols-3 md:justify-between justify-center">
              {/* ROW 1 */}
              <DetailCard
                icon={<CheckIcon className="h-16 w-16" />}
                title={"A Holistic Approach"}
                paragraph={
                  "You need to be injury-free if you want to get the most from training. Likewise, avoiding injury requires a solid foundation of strength. At Arke Fitness, we maximise your results by combining both practices into one."
                }
              />
              <DetailCard
                icon={<CheckIcon className="h-16 w-16" />}
                title={"One That Truly Cares"}
                paragraph={
                  "Not 'just another service' to help you achieve your physical goals - form a genuine relationship with your practitioner. Physio sessions & workouts are challenging enough - so you may as well have a good time with a friend while doing them."
                }
              />
              <DetailCard
                icon={<CheckIcon className="h-16 w-16" />}
                title={"Fully Customised Program"}
                paragraph={
                  "We create a custom plan that is only specific to you. We don't believe in the one-size fits all approach - your situation is unique so we treat it as such."
                }
              />

              {/* ROW 2 */}
              <DetailCard
                icon={<CheckIcon className="h-16 w-16" />}
                title={"Achieve Fast Results"}
                paragraph={
                  "Our goal is get you to look & feel your best as soon as possible. Seeing immediate results after your first few sessions is nothing less than our expectation."
                }
              />
              <DetailCard
                icon={<CheckIcon className="h-16 w-16" />}
                title={"Exceed Expectations"}
                paragraph={
                  "Physical health is a lifestyle - it doesn't stop once you walk out the door. That's why not only do we work with you, we teach you everything you need to know so eventually you'll be able to do it on your own."
                }
              />
              <DetailCard
                icon={<CheckIcon className="h-16 w-16" />}
                title={"Private Space"}
                paragraph={
                  "Use state of the art equipment and latest methods in a 1:1 setting. Achieve maximum results when your practitioner's sole focus is only on you."
                }
              />
            </div>

            <div className="sm:mt-0 text-center">
              <a href="https://wa.me/message/P7PJU7ZDBVEYF1" target="_blank" rel="noreferrer">
                <a className="af-button-primary md:af-button-big shadow">Reach out to us today</a>
              </a>
            </div>
          </div>
        </div>

        {/* Meet Haikal & Dan */}
        <div className="relative bg-almostwhite-500">
          <div className="grid md:grid-cols-2 max-w-7xl mx-auto p-6">
            <div className="relative aspect-square md:aspect-auto">
              <Image
                layout="fill"
                src="/images/haikal + dan.jpg"
                alt="Haikal + Dan"
                className="object-cover object-top"
              />
            </div>
            <div className="md:p-6 md:pl-12 space-y-6">
              <h2 className="font-arke mt-6 text-5xl font-bold md:text-4xl uppercase tracking-wide">
                Meet Haikal & Dan
              </h2>
              <h3 className=" mt-6 text-2xl font-bold md:text-xl tracking-wide">Co-Founders of Arke Fitness.</h3>
              <p className="font-sans text-lg font-light">
                We've been friends for 6 years: met at the gym, did freelance together, and now we're building Arke
                together (you could call this a "bromance"...).
                <br />
                <br />
                We both live an active lifestyle, we've competed in bodybuilding & powerlifting competitions (we even
                stood on podiums 😉).
                <br />
                <br />
                And like most active people, we've experienced what it's like going to physiotherapy to fix an injury
                only for it to come back again.
                <br />
                <br />
                Same with training, we know what it's like to push too hard only to get injured and be out for weeks.
                <br />
                <br />
                We have searched far and wide for a solution, but a lot of today's methods focus on short term results
                while overlooking the long term.
                <br />
                <br />
                That's why we experimented for over a year (not only with clients but ourselves) to come up with the
                perfect solution.
                <br />
                <br />
                And that's what the Arke System is all about -{" "}
                <b className="font-extrabold">We aim to become the standard of physical care in Malaysia.</b>
                <br />
                <br />
                By incorporating both physiotherapy & personal training best practices, we enable clients to workout
                with full intensity knowing that they are safely avoiding risk of injury.
                <br />
                <br />
                Those with injuries not only recover, but they become even stronger as they learn how to strengthen &
                condition their body the right way.
                <br />
                <br />
                With 100's of successful testimonials, we're confident that this is the future of physical care in
                Malaysia.
              </p>
              <div>
                <a href="https://wa.me/message/AESXYUNFR6NNK1" target="_blank" rel="noreferrer">
                  <a className="af-link-secondary">Give us a chance to prove it to you</a>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* OUR PACKAGES
        <div className="relative bg-almostwhite-500">
          <div className="grid md:grid-cols-2 max-w-7xl mx-auto p-6">
            <div className="md:order-last relative aspect-square w-full">
              <Image
                layout="fill"
                className="w-full h-full object-cover"
                src="/images/home-our-packages.jpg"
                alt="Support team"
              />
            </div>
            <div className="md:p-6 md:pr-12 h-full space-y-6">
              <h2 className="font-arke mt-6 text-5xl font-bold md:text-4xl uppercase tracking-wide">Our packages</h2>
              <p className="font-sans text-lg font-light">
                Whether your first visit with us is also your first workout session or your 100-th time, our team of
                Personal Trainers and Physiotherapists will meet you right where you are in your journey – we're
                flexible like that.
              </p>
              <div>
                <Link href="/packages">
                  <a className="af-link-secondary">Explore our packages</a>
                </Link>
              </div>
            </div>
          </div>
        </div> */}

        {/* WHY WORK WITH US */}
        {/* <div className="bg-almostwhite-500">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="font-arke mt-6 text-5xl font-bold md:text-4xl uppercase tracking-wide">
              Why should you work with us?
            </h2>
            <ul role="list" className="mt-12 space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:gap-y-12 sm:space-y-0">
              {why.map((person) => (
                <li key={person.title}>
                  <div className="space-y-4">
                    <div className="relative aspect-square">
                      <Image
                        layout="fill"
                        className="w-full h-full object-cover"
                        src={`/images/${person.imageUrl}.jpg`}
                        alt="Support team"
                      />
                    </div>

                    <div className="space-y-2">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>{person.title}</h3>
                      </div>
                      <p className="text-gray-700">{person.description}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

        {reviews && reviews.length && (
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <Testimonials reviews={reviews} />
          </div>
        )}

        {/* KEY BENEFITS */}
        {/*<div className="bg-almostwhite-500">*/}
        {/*  <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">*/}
        {/*    <h2 className="font-arke mt-6 text-5xl font-bold md:text-4xl uppercase tracking-wide">Key Benefits</h2>*/}
        {/*    <p className="mt-4 max-w-3xl text-lg text-almostblack-400">Why you should work with us.</p>*/}
        {/*    <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">*/}
        {/*      {benefits.map((feature) => (*/}
        {/*        <div key={feature.name}>*/}
        {/*          <div>*/}
        {/*            <span className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 bg-opacity-10">*/}
        {/*              <feature.icon className="h-6 w-6 text-almostblack-300" aria-hidden="true" />*/}
        {/*            </span>*/}
        {/*          </div>*/}
        {/*          <div className="mt-6">*/}
        {/*            <h3 className="font-accent text-2xl font-bold text-red-500">{feature.name}</h3>*/}
        {/*            <p className="mt-2 text-base text-almostblack-300">{feature.description}</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </main>
    </>
  );
}
